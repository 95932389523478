<template>
  <div class="contents-area select-product-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <button
            type="button"
            class="btn btn-short btn-round"
            @click="$router.back()"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.21802 7.83336H13.3327V9.1667H5.21802L8.79402 12.7427L7.85135 13.6854L2.66602 8.50003L7.85135 3.3147L8.79402 4.25736L5.21802 7.83336Z"
                fill="#2D2E3C"
              />
            </svg>
            {{ translations("Back") }}
          </button>
          <Breadcrumbs
            :addItem="{ name: translations('Search'), path: '/search' }"
          />
        </div>
        <div class="select-products" v-if="productsLoaded">
          <h3 class="grey">
            {{
              curCompany.name +
              (curCompany.inn && curCompany.inn !== "-"
                ? " - " + curCompany.inn
                : "") +
              " ( " +
              curCompany.country +
              " )"
            }}
          </h3>
          <h1>{{ curProductType.name }}</h1>
          <div class="products-cont">
            <ProductList
              :productType="curProductTypeId"
              :companyId="curCompany.id"
            />
          </div>
        </div>
        <div class="commercial-offer-block" v-if="productsLoaded">
          <h3>
            {{ translations("CommercialOfferText") }}
            <a @click="getCommercialOffer()" class="link">
              {{ translations("CommercialOffer") }}
            </a>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import ProductList from "@/components/egrul/search/ProductList";

export default {
  name: "SelectProduct",
  components: { ProductList },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "productsLoaded",
      "listCurProductId",
      "curCompany",
      "curCountry",
      "curProductTypeId",
      "curProductType",
      "listCurProduct",
      "isUserLogged",
      "ListLoggedUser",
    ]),
  },
  methods: {
    ...mapActions(["fetchCommercialOffer"]),
    async getCommercialOffer() {
      await this.fetchCommercialOffer({
        lang: this.$cookies.get("user_language"),
        company: this.curCompany,
        countryId: this.curCountry.id,
      });
    },
  },
  created() {
    if (!this.productsLoaded) {
      this.$router.push("/");
    }
  },
};
</script>